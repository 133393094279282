.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
  
  .logo {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 50px;
  }
  
  .innerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    flex-grow: 1; /* This allows this element to take up remaining space, adjusting its size */
  }