.main {
  display: grid;
  gap: 1rem;
  justify-content: center;
  place-content: center;
  justify-items: center;
  padding-bottom: 100px;
}

.tabContainer {
  width: 100%;
  max-width: 500px;
  padding-inline: 2rem;
}

.tabSelect {
  display: flex;
  justify-content: stretch;
  gap: 0.125rem;
  padding: 0.125rem;
  margin: 0 auto 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
}

.tabSelect > * {
  width: 100%;
}

.tabContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
}

.inputField {
  font-size: 1rem; 
  line-height: 1.5;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
  padding: 0.375rem 0.75rem;
  width: 100%;
  padding: 1rem;
}

.inputField:focus{
  border: 1px solid;
  border-radius: 0.5rem;
  border-color: #5404A4;
  outline: thin dotted #5404A4;
}

.startMeeting {
  font-size: 1rem; 
  border: 2px solid #5404A4;
  border-radius: 2rem;
  padding: 0.375rem 0.75rem;
  width: 100%;
  background-color: #5404A4;
  padding: 0.5rem;
}

.helperText {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6c757d;
}